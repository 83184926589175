import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Icons,
  InputNumber,
} from "@pankod/refine-antd";
import { definitions } from "assets/definitions";
import { FC } from "react";

const { InfoCircleOutlined } = Icons;

export const AditionalInformation: FC = () => {
  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="GIA Report Number" name="giaReportNumber">
            <Input maxLength={30} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Office code" name="officeCode">
            <Input maxLength={50} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Depth" name="depth">
            <InputNumber min={0} max={100} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Group" name="group">
            <Select options={definitions["group"]} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Table" name="table">
            <InputNumber min={0} max={100} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Strong" name="strong">
            <InputNumber min={0} max={100} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Medium" name="medium">
            <InputNumber min={0} max={100} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Weak" name="weak">
            <InputNumber min={0} max={100} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Unclassified" name="unclassified">
            <InputNumber min={0} max={100} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
