import React, { FC, useContext, useEffect, useState } from "react";

import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Icons,
  Upload,
  Button,
  message,
  Spin,
} from "@pankod/refine-antd";
import { Storage } from "aws-amplify";

interface IBasicInformationProps {
  isFullScreen?: boolean;
  form?: any;
  code?: any;
}

const { InfoCircleOutlined, PlusOutlined, UploadOutlined, InboxOutlined } =
  Icons;

export const FileUpload: FC<IBasicInformationProps> = (props) => {
  const [imageLoading, setImageLoading] = useState({
    loading: false,
    name: "",
  });
  const [videoLoading, setVideoLoading] = useState({
    loading: false,
    name: "",
  });
  const [txtLoading, setTxtLoading] = useState({ loading: false, name: "" });
  const { code } = props;

  const handleUploadImage = async (file: any) => {
    setImageLoading({ loading: true, name: "" });
    const type = file.name.split(".")[1];
    try {
      const result = await Storage.put(`${code}/image.${type}`, file, {
        contentType: file.type,
      });
      console.log("Uploaded file:", result);
      // message.success("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading file:", error);
      message.error("Failed to upload file");
    } finally {
      setImageLoading({ loading: false, name: file.name });
    }
  };

  // const handleUploadVideo = async (file: any) => {
  //   setVideoLoading({ loading: true, name: "" });

  //   try {
  //     const result = await Storage.put(`${code}/video.mp4`, file, {
  //       contentType: file.type,
  //     });
  //     console.log("Uploaded file:", result);
  //     // message.success("File uploaded successfully");
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //     message.error("Failed to upload file");
  //   } finally {
  //     setVideoLoading({ loading: false, name: file.name });
  //   }
  // };
  const [imageList, setImageList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [txtList, setTxtList] = useState([]);

  const handleImageChange = ({ fileList }: any) => {
    setImageList(fileList.slice(-1)); // Allow only one file
  };

  const handleVideoChange = ({ fileList }: any) => {
    setVideoList(fileList.slice(-1)); // Allow only one file
  };

  const handleTxtChange = ({ fileList }: any) => {
    setTxtList(fileList.slice(-1)); // Allow only one file
  };

  // const handleUploadTxt = async (file: any) => {
  //   setTxtLoading({ loading: true, name: "" });

  //   try {
  //     const result = await Storage.put(`${code}/spectrogram.txt`, file, {
  //       contentType: file.type,
  //     });
  //     console.log("Uploaded file:", result);
  //     // message.success("File uploaded successfully");
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //     message.error("Failed to upload file");
  //   } finally {
  //     setTxtLoading({ loading: false, name: file.name });
  //   }
  // };

  return (
    <>
      {/* <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Image"
            name="image"
            rules={[
              {
                required: true,
                message: "Image is required",
              },
            ]}
            valuePropName="fileList"
            getValueFromEvent={(e: any) => {
              console.log('Upload event:', e);
              if (Array.isArray(e)) {
                return e;
              }
              return e?.fileList;
            }}
            
          >
            <Upload
            
              accept="image/png, image/jpeg"
              // maxCount={1}
              // action=""
              showUploadList={false}
              // customRequest={({ file }) => handleUploadImage(file)}
              //  showUploadList={false}
              action="/upload.do"

            >
              <Input
                placeholder={
                  imageLoading.loading
                    ? "Uploading image..."
                    : "Click to upload image"
                }
                suffix={
                  imageLoading.loading ? (
                    <Spin size="small" />
                  ) : (
                    <UploadOutlined />
                  )
                }
                className="ant-button"
              />
            </Upload>
            {imageLoading.name}
          </Form.Item>
        </Col>
      </Row> */}

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="image"
            label="Upload Image"
            getValueFromEvent={(event) => {
              if (Array.isArray(event)) {
                return event;
              }
              return event && event.fileList;
            }}
            rules={[
              {
                validator: (_, value) => {
                  if (value && value.length === 1) {
                    const file = value[0];
                    const isImage = file.type.startsWith("image/");
                    if (isImage) {
                      return Promise.resolve();
                    }
                  }
                  return Promise.reject(
                    new Error("Please upload an image file")
                  );
                },
              },
            ]}
          >
            <Upload
              beforeUpload={() => false}
              accept="image/*"
              fileList={imageList}
              onRemove={() => setImageList([])}
              maxCount={1}
              multiple={false}
              onChange={handleImageChange}
            >
              <Input
                placeholder="Click to upload image"
                suffix={
                  imageLoading.loading ? (
                    <Spin size="small" />
                  ) : (
                    <UploadOutlined />
                  )
                }
                className="ant-button"
              />
            </Upload>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="video"
            label="Upload Video"
            getValueFromEvent={(event) => {
              if (Array.isArray(event)) {
                return event;
              }
              return event && event.fileList;
            }}
            rules={[
              {
                validator: (_, value) => {
                  if (value && value.length === 1) {
                    const file = value[0];
                    const isImage = file.type.startsWith("video/");
                    if (isImage) {
                      return Promise.resolve();
                    }
                  }
                  return Promise.reject(
                    new Error("Please upload a video file")
                  );
                },
              },
            ]}
          >
            <Upload
              beforeUpload={() => false}
              accept="video/mp4"
              fileList={videoList}
              onRemove={() => setVideoList([])}
              maxCount={1}
              multiple={false}
              onChange={handleVideoChange}
            >
              <Input
                placeholder="Click to upload video"
                suffix={
                  imageLoading.loading ? (
                    <Spin size="small" />
                  ) : (
                    <UploadOutlined />
                  )
                }
                className="ant-button"
              />
            </Upload>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="spectrogram"
            label="Upload Spectrogram"
            getValueFromEvent={(event) => {
              if (Array.isArray(event)) {
                return event;
              }
              return event && event.fileList;
            }}
            // rules={[
            //   {
            //     validator: (_, value) => {
            //       if (value && value.length === 1) {
            //         const file = value[0];
            //         const isImage = file.type.startsWith("video/");
            //         if (isImage) {
            //           return Promise.resolve();
            //         }
            //       }
            //       return Promise.reject(
            //         new Error("Please upload a video file")
            //       );
            //     },
            //   },
            // ]}
          >
            <Upload
              beforeUpload={() => false}
              accept=".txt"
              fileList={txtList}
              onRemove={() => setTxtList([])}
              maxCount={1}
              multiple={false}
              onChange={handleTxtChange}
            >
              <Input
                placeholder="Click to upload txt"
                suffix={
                  imageLoading.loading ? (
                    <Spin size="small" />
                  ) : (
                    <UploadOutlined />
                  )
                }
                className="ant-button"
              />
            </Upload>
          </Form.Item>
        </Col>
      </Row>

      {/* <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="JSON files" valuePropName="jsonFiles">
            <Upload.Dragger beforeUpload={() => false} multiple>
              <p>
                <InboxOutlined style={{ fontSize: 40 }} />
              </p>
              <span className="ant-upload-text">
                Click or drag file to this area to upload
              </span>
            </Upload.Dragger>
          </Form.Item>
        </Col>
      </Row> */}

      <Row gutter={16}>
        <Col span={24}>
          {/* <Form.Item label="Spectrogram" name="spectrogram">
            <Upload
              action=""
              showUploadList={false}
              accept=".txt"
              maxCount={1}
              customRequest={(e) => handleUploadTxt(e.file)}
            >
              <Input
                placeholder={
                  txtLoading.loading
                    ? "Uploading spectrogram file..."
                    : "Click to upload spectrogram file"
                }
                suffix={
                  txtLoading.loading ? (
                    <Spin size="small" />
                  ) : (
                    <UploadOutlined />
                  )
                }
                className="ant-button"
              />
              {txtLoading.name}
            </Upload>
          </Form.Item> */}
        </Col>
      </Row>
    </>
  );
};
