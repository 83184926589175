import React, { useEffect, useState } from "react";
import { IResourceComponentsProps, useNavigation } from "@pankod/refine-core";

import {
  Create,
  Form,
  Input,
  Select,
  Button,
  SaveButton,
  useSelect,
  useStepsForm,
  Steps,
  Icons,
  Space,
  Row,
  Col,
  Divider,
} from "@pankod/refine-antd";
import { BasicInformation } from "./basicInformation";
import { AditionalInformation } from "./additionalInformation";
import { FileUpload } from "./fileUpload";
import { nanoid } from "nanoid";
import { Storage } from "aws-amplify";

const { Step } = Steps;



export const DiamondCreate: React.FC<any> = (props) => {
  const { modalClose } = props;
  const { show } = useNavigation();
  const {
    current,
    gotoStep,
    stepsProps,
    formProps,
    saveButtonProps,
    onFinish,
    form,
    formValues,
    formResult,
  } = useStepsForm({
    //   redirect: "show",
    onMutationSuccess: () => modalClose(),
    submit: (values: any) => {
      submit(values);
      //   // onFinish(formValues)
      //   // alert("ewfr4eg");
    },
  });

  const [imageLoading, setImageLoading] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);

  const renderWeightCategory = (value: any) => {
    const weightCategoryOptions = [
      { value: 1, label: "0 - 0.49" },
      { value: 2, label: "0.5 - 0.99" },
      { value: 3, label: "1 - 1.49" },
      { value: 4, label: "1.5 - 1.99" },
      { value: 5, label: "2 - 2.49" },
      { value: 6, label: "2.5 - 2.99" },
      { value: 7, label: "3 - 4.99" },
      { value: 8, label: "5 - 7.99" },
      { value: 9, label: "8 - 9.99" },
      { value: 10, label: "10 - 19.99" },
      { value: 11, label: "20+" },
    ];

    const numericValue = parseFloat(value);

    // Check for "20+"
    if (numericValue >= 20) {
      return 11
    }

    const selectedOption = weightCategoryOptions.find((option) => {
      const [start, end] = option.label.split(" - ").map(parseFloat);
      return numericValue >= start && numericValue <= end;
    });


    return selectedOption?.value;
  };

  

 

  const submit = async (values: any) => {
    console.log(formValues);
    // if (!(values["image"] && values["video"])) return;
    const { image, video, spectrogram, code, weight } = values;
    const id = code
    const imgType = image[0].name.split(".")[1];

    try {
      setImageLoading(true);
      const result = await Storage.put(
        `diamonds/${id}/image.${imgType}`,
        image[0].originFileObj,
        {
          contentType: image[0].type,
          customPrefix: {
            public:''
            }
        }
      );
      console.log("Uploaded file:", result);
      // message.success("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading file:", error);
      // message.error("Failed to upload file");
    } finally {
      setImageLoading(false);
    }

    try {
      setVideoLoading(true);
      const result = await Storage.put(
        `diamonds/${id}/video.mp4`,
        video[0].originFileObj,
        
        {
          contentType: video[0].type,
          customPrefix: {
            public:''
            }
        }
      );
      // console.log("Uploaded file:", result);
      // message.success("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading file:", error);
      // message.error("Failed to upload file");
    } finally {
      setVideoLoading(false);
    }

    if (spectrogram) {
      try {
        // setVideoLoading(true);
        const result = await Storage.put(
          `diamonds/${id}/spectrogram.txt`,
          spectrogram[0].originFileObj,
          {
            contentType: "text/plain",
            customPrefix: {
              public:''
              }
          }
        );
        // console.log("Uploaded file:", result);
        // message.success("File uploaded successfully");
      } catch (error) {
        console.error("Error uploading file:", error);
        // message.error("Failed to upload file");
      } finally {
        setVideoLoading(false);
      }
    }
    // const imgVal = values.image;

    const imageVal = `${id}/image.${imgType}`;
    const videoVal = `${id}/video.mp4`;
    const spectrogramVal = `${id}/spectrogram.txt`;
    const createdAt_ = new Date().toISOString();

    onFinish({
      ...values,
      id,
      image: imageVal,
      video: videoVal,
      spectrogram: spectrogramVal,
      createdAt_,
      weightCategory: renderWeightCategory(weight)
    });
  };

  const formList = [
    <BasicInformation />,
    <AditionalInformation />,
    <FileUpload 
    //code={}
    />,
  ];

  return (
    <Create
      goBack={null}
      wrapperProps={undefined}
      breadcrumb={false}
      headerProps={{
        subTitle: null,
        title: null,
        backIcon: null,
        extra: null,
        style: {
          padding: "0px",
        },
      }}
      title={null}
      headerButtons={null}
      footerButtons={
        <Space style={{ display: "flex", float: "left" }}>
          {current > 0 && (
            <Button
              style={{ width: "140px" }}
              onClick={() => {
                gotoStep(current - 1);
              }}
            >
              Previous
            </Button>
          )}
          {current < formList.length - 1 && (
            <Button
              style={{ width: "140px" }}
              onClick={() => {
                gotoStep(current + 1);
              }}
            >
              Next
            </Button>
          )}
          {current === formList.length - 1 && (
            <SaveButton
              {...saveButtonProps}
              style={{ width: "140px" }}
              disabled={imageLoading || videoLoading ? true : false}
              loading={imageLoading || videoLoading ? true : false}
            >
              {imageLoading || videoLoading ? "Uploading..." : "Add Diamond"}
            </SaveButton>
          )}
        </Space>
      }
    >
      <Row gutter={16} style={{ margin: 0, padding: 0 }}>
        <Col span={9}>
          <Space size={0}>
            <Steps
              size="small"
              style={{ minHeight: 260 }}
              {...stepsProps}
              direction="vertical"
              items={[
                {
                  title: "Basic Information",
                },
                {
                  title: "Additional Information",
                },
                {
                  title: "File Upload",
                },
              ]}
            />
            <Divider type="vertical" style={{ minHeight: 220 }} />
          </Space>
        </Col>

        <Col span={15}>
          <Form {...formProps} layout="vertical">
            {formList[current]}
          </Form>
        </Col>
      </Row>
    </Create>
  );
};
