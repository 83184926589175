import React from "react";

import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  ReadyPage,
  ErrorComponent,
  AuthPage,
} from "@pankod/refine-antd";

import dataProvider from "@pankod/refine-simple-rest";
import { AntdInferencer } from "@pankod/refine-inferencer/antd";
import routerProvider, {
  BrowserRouterComponent,
} from "@pankod/refine-react-router-v6";

import { authProvider } from "./authProvider";
import { PostList } from "pages/main/list/list";
import { Header } from "components/layout/header";

import "style.css";
import { Layout } from "components/layout/layout";
import { DiamondShow } from "pages/main/show/show";
import dataProviderAmp from "providers/dataProviderAmp";
import { Authenticator } from "@aws-amplify/ui-react";
import { components } from "components/login";


function App() {
  return (
    <Authenticator components={components} hideSignUp>
      {({ signOut, user }) => {
        return (
          <Refine
            dataProvider={dataProviderAmp}
            notificationProvider={notificationProvider}
            ReadyPage={ReadyPage}
            catchAll={<ErrorComponent />}
            resources={[
              {
                name: "diamonds",
                list: PostList,
                show: DiamondShow,
                create: AntdInferencer,
                canDelete: true,
              },
            ]}
            routerProvider={routerProvider}
            Header={() => <Header user={user} signout={signOut} />}
            Sider={undefined}
            Layout={Layout}
            // authProvider={authProvider}
            // LoginPage={() => (
            //   <AuthPage
            //     registerLink={false}
            //     contentProps={{
            //       title: <span className="logo">PAPUSHADO</span>,
            //       headStyle: {
            //         textAlign: "center",
            //       },
            //     }}
            //     wrapperProps={{
            //       style: {
            //         background: "linear-gradient(to right, #E2EDF9, #84BEED)",
            //       },
            //     }}
            //   />
            // )}
          />
        );
      }}
    </Authenticator>
  );
}

export default App;
