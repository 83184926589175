import React from "react";
import { createRoot } from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import App from "./App";

import "style.css";
import { BrowserRouter, MemoryRouter } from "react-router-dom";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
Amplify.configure(config);

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

// if (!window.location.pathname.includes("papushado-front")) {
//   window.history.replaceState(
//     "",
//     "",
//     "papushado-front" + window.location.pathname
//   );
// }

const theme = {
  name: 'pretty-princess',
  tokens: {
    colors: {
      // button: {
      //   primary: { value: 'hotpink' }
      // },
       font: {
          interactive: {
            value: "black",
          },
        },
    }
  }
}

root.render(
  <AmplifyProvider theme={theme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </AmplifyProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
