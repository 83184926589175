import React, { useEffect, useState } from "react";
import {
  IResourceComponentsProps,
  useShow,
  useOne,
  useMany,
  useNavigation,
  useModal,
  useDelete,
} from "@pankod/refine-core";
import {
  Show,
  Typography,
  NumberField,
  TagField,
  TextField,
  MarkdownField,
  DateField,
  ImageField,
  Button,
  Space,
  Row,
  Col,
  Card,
  EditButton,
  DeleteButton,
  Upload,
  Icons,
  UploadProps,
  useModalForm,
  Form,
  Divider,
  Modal,
  Dropdown,
  RefreshButton,
} from "@pankod/refine-antd";
import type { MenuProps } from "antd";

// import {  Modal} from 'antd'
import { PostList } from "pages/main/list/list";
import ReactPlayer from "react-player";
import { useNavigate } from "@pankod/refine-react-router-v6";
import { EditForm } from "pages/forms/edit/edit";
import { AddRecutForm } from "pages/forms/edit/addRecut";
import { Storage } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import SpectrogramPlot from "components/SpectrogramPlot";
import munsellData from '../../../assets/data/munsell_real.json';
// import MunsellColorPicker from "components/munsell";
import CircularGrid from "components/munsell";

const { Title } = Typography;
const {
  ArrowLeftOutlined,
  LeftOutlined,
  UploadOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} = Icons;

// const props: UploadProps = {
//   action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
//   onChange({ file, fileList }) {
//     if (file.status !== "uploading") {
//       console.log(file, fileList);
//     }
//   },
//   defaultFileList: [
//     {
//       uid: "1",
//       name: "xxx.txt",
//       status: "done",
//       url: "https://example-files.online-convert.com/document/txt/example.txt",
//       percent: 33,
//     },
//     {
//       uid: "2",
//       name: "yyy.txt",
//       status: "done",
//       url: "https://example-files.online-convert.com/document/txt/example.txt",
//     },
//     {
//       uid: "3",
//       name: "zzz.txt",
//       status: "done",
//       url: "https://example-files.online-convert.com/document/txt/example.txt",
//     },
//   ],
// };

export const DiamondShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [videoURL, setVideoURL] = useState<any>(record?.video);
  const [imageURL, setImageURL] = useState<any>(record?.image);

  // const [modal, contextHolder] = Modal.useModal();

  const id: any = record?.id;

  const { confirm } = Modal;

  // const fetchMedia = async () => {
  //   if (!id) return;
  //   // const image: any = record?.image;
  //   // const video: any = record?.video;

  //   // console.log(id, image, video);
  //   const imageKey = await Storage.get(`${record?.image}`, { expires: 60 });
  //   const videoKey = await Storage.get(`${id}/video.mp4`, { expires: 60 });

  //   setImageURL(imageKey);
  //   setVideoURL(videoKey);
  // };

  useEffect(() => {
    console.log(record?.spectraScores)
  }, [data]);

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editModalShow,
    onFinish: onEditFinish,
  } = useModalForm({
    id,
    autoResetForm: true,
    action: "edit",
    warnWhenUnsavedChanges: true,
    redirect: false,
    // submit(formValues) {
    //   console.log(formValues)
    // },
  });

  const {
    modalProps: recutModalProps,
    formProps: recutFormProps,
    show: recutModalShow,
    onFinish: onRecutEditFinish,
  } = useModalForm({
    id,
    autoResetForm: true,
    action: "edit",
    warnWhenUnsavedChanges: true,
    redirect: false,
    // submit(formValues) {
    //   console.log(formValues)
    // },
  });



  const onEditSubmit = (values: any) => {
    onEditFinish?.({
      ...values,
      user: user?.username,
      status: "edited",
    });
  };

  const onRecutSubmit = (values: any) => {
    if (values?.recutResult === undefined) {
      values.recutResult = 0
    }
    onRecutEditFinish?.({
      ...values,
      user: user?.username,
      status: "edited",

    });
  };

  const items: MenuProps["items"] = [
    {
      label: "Edit records",
      key: "records",
      icon: <EditOutlined />,
      onClick: () => editModalShow(record?.id),
    },
    {
      label: "Add recut results",
      key: "recut",
      icon: <EditOutlined />,
      onClick: () => recutModalShow(record?.id),
    },
    {
      label: "Edit similarity levels",
      key: "sl",
      icon: <EditOutlined />,
    },
  ];

  const menuProps = {
    items,
    onClick: (key: any) => console.log(key, 13),
  };

  const { mutate } = useDelete();

  const { list } = useNavigation();

  const [mediaVisible, setMediaVisible] = useState<boolean>(true);

  const confirmDelete = () => {
    confirm({
      title: "You are about to delete a diamond!",
      icon: <ExclamationCircleOutlined />,
      content: "Once a diamond's data is deleted, it cannot be restored.",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => {
        Storage.remove(`${id}/`).then(() =>
          mutate(
            {
              resource: "diamonds",
              id,
            },
            {
              onSuccess: () => {
                list("diamonds");
              },
            }
          )
        );
      },
    });
  };

  return (
    <Show
      wrapperProps={{
        style: {
          padding: 0,
        },
      }}
      isLoading={isLoading}
      headerProps={{
        subTitle: null,
        extra: (
          <Space>
            {/* <EditButton
              recordItemId={record?.id}
              onClick={() => editModalShow(record?.id)}
              style={{ width: "125px" }}
            /> */}

            <Dropdown.Button
              menu={menuProps}
              onClick={() => editModalShow(record?.id)}
              // style={{ width: "225px" }}
            >
              Edit Options
            </Dropdown.Button>

            <Button
              danger={false}
              onClick={confirmDelete}
              style={{ width: "125px" }}
              
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
            <Button
              icon={mediaVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              onClick={() => setMediaVisible(!mediaVisible)}
            >
              {mediaVisible ? "Hide Media" : "Show Media"}
            </Button>
            <RefreshButton />
          </Space>
        ),
        // extra: null,
        style: { padding: 0 },
        title: <span>{record?.code ? `Diamond ${record?.code}` : ""}</span>,
        breadcrumb: undefined,

        // backIcon: <LeftOutlined />,

        onBack: () => list("diamonds"),
      }}
    >
      <Row gutter={24} style={{ display: mediaVisible ? "flex" : "none" }}>
        <Col span={8}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              border: "0.5px solid #e8e7ec",
            }}
          >
            <ImageField
              // width="100%"
              value={`https://papushado-storage-amp105130-dev.s3.amazonaws.com/diamonds/${record?.image}`}
              style={{
                maxHeight: "300px",
                minHeight: "300px",
              }}
            />
          </div>
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            justifyContent: "center",
            border: "0.5px solid #e8e7ec",
          }}
        >
          <ReactPlayer
            controls
            url={`https://papushado-storage-amp105130-dev.s3.amazonaws.com/diamonds/${record?.video}`}
            style={{ maxHeight: "300px" }}
            width="100%"
            height="100%"
          />
        </Col>

        <Col span={8}>
          <Card
            size="small"
            style={{
              maxHeight: "300px",
              minHeight: "300px",
              overflowY: "auto",
            }}
            title={
              <span style={{ color: "#9F9DA3" }}>
               Spectrogram Plot
              </span>
            }
          >
            <SpectrogramPlot path={`diamonds/${id}`} width={400} height={300}/>
            {/* <CircularGrid /> */}
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: 16, padding: 0 }}>
        <Col span={24}>
          {record?.sl !== "{}" && record?.sl  ? (
            <PostList canCreate={false} sl selected={record} sticky />
          ) : (
            <div>Diamond doesn't contain any pair with Similarity Levels</div>
          )}
        </Col>
      </Row>

      <Modal
        {...editModalProps}
        width={466}
        bodyStyle={{ paddingTop: 3 }}
        style={{ top: 20 }}
      >
        <Form {...editFormProps} onFinish={onEditSubmit} layout="vertical">
          <EditForm />
        </Form>
      </Modal>
      <Modal {...recutModalProps} width={466} bodyStyle={{ paddingTop: 3 }}>
        <Form {...recutFormProps} onFinish={onRecutSubmit} layout="vertical">
          <AddRecutForm />
        </Form>
      </Modal>
    </Show>
  );
};
