import React from "react";

import { LayoutProps } from "@pankod/refine-core";
import { AntdLayout } from "@pankod/refine-antd";
import { Header } from "../header";

interface ILayoutExtended extends LayoutProps  {
  user? :any
  signout?: any
}

// interface LayoutProps extends ILayoutExtended;

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <AntdLayout>
      <Header />
      <AntdLayout.Content>
        <div className="content">{children}</div>
      </AntdLayout.Content>
    </AntdLayout>
  );
};
