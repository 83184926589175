import { DataProvider } from "@pankod/refine-core";
import { API, graphqlOperation } from "aws-amplify";
import {
  createDiamond,
  deleteDiamond,
  updateDiamond,
} from "../graphql/mutations";
import { getDiamond, listDiamonds } from "../graphql/queries";

const dataProviderAmp: DataProvider = {
  create: async ({ resource = "", variables }) => {
    const data: any = await API.graphql(
      graphqlOperation(createDiamond, {
        input: variables,
      })
    );

    return { data: data.data.createDiamond };
  },
  deleteOne: async ({ resource = "", id }) => {
    const data: any = await API.graphql(
      graphqlOperation(deleteDiamond, {
        input: { id },
      })
    );

    return data;
  },
  getList: async ({ resource = "", pagination, sort, filters, metaData }) => {
    const filters_: any = filters;

    function filterToVarables(filters: any) {
      let variables: any = { filter: {} };

      if (!filters || filters.length === 0) return variables;

      const andFilters: any[] = [];

      for (const item of filters) {
        const { field, operator, value } = item;

        if (
          value === undefined ||
          value === null ||
          (Array.isArray(value) && value.length === 0)
        )
          continue;

        if (
          (field === "scale" || field === "weight") &&
          Array.isArray(value) &&
          value.length === 2
        ) {
          andFilters.push({ [field]: { between: value } });
        } else if (operator === "in" && Array.isArray(value)) {
          console.log("in");
          const orConditions = value.map((val: any) => ({
            [field]: { eq: val },
          }));
          andFilters.push({ or: orConditions });
        } else if (
          operator === "between" &&
          Array.isArray(value) &&
          value.length === 2
        ) {
          console.log("between");
          andFilters.push({ [field]: { between: value } });
        } else if (field === "code") {
          const orConditions = [
            { code: { contains: value } },
            { giaReportNumber: { contains: value } },
          ];
          andFilters.push({ or: orConditions });
        } else {
          andFilters.push({ [field]: { [operator]: value } });
        }
      }

      if (andFilters.length > 0) {
        variables.filter.and = andFilters;
      }

      return variables;
    }

    async function fetchAllRecords(query: any, variables: any) {
      let allRecords: any[] = [];
      let nextToken = null;

      do {
        const response: any = await API.graphql({
          query,
          variables: { ...variables, nextToken, limit: 1000 },
        });
        const { items, nextToken: newNextToken } = response.data.listDiamonds;
        allRecords = allRecords.concat(items);
        nextToken = newNextToken;
      } while (nextToken);

      return allRecords;
    }

    const allData = await fetchAllRecords(listDiamonds, filterToVarables(filters_));

    return { data: allData, total: allData?.length };
  },
  getOne: async ({ resource = "", id }) => {
    const data: any = await API.graphql({
      query: getDiamond,
      variables: { id },
    });

    const single = data.data.getDiamond;
    return { data: single };
  },
  update: async ({ resource = "", id, variables }) => {
    const data: any = await API.graphql(
      graphqlOperation(updateDiamond, {
        input: { ...variables, id },
      })
    );

    return { data: data.data.updateDiamond };
  },
  getApiUrl: () => "",
};

export default dataProviderAmp;
