export const definitions = {
    shape: [
        {
            value: 1,
            label: "Pear"
        },
        {
            value: 2,
            label: "Cushion"
        },
        {
            value: 3,
            label: "Radiant"
        },
        {
            value: 4,
            label: "Oval"
        },
        {
            value: 5,
            label: "Round"
        },
        {
            value: 6,
            label: "Heart"
        },
        {
            value: 7,
            label: "RB-Radiant"
        },
        {
            value: 8,
            label: "RB-Cushion"
        },
        {
            value: 9,
            label: "Indian Radiant"
        },
        {
            value: 10,
            label: "Indian Cushion"
        },
        {
            value: 11,
            label: "Emerald"
        },
        {
            value: 12,
            label: "Old Mined"
        },
        {
            value: 13,
            label: "Marquise"
        },
        {
            value: 14,
            label: "Princess"
        },
        {
            value: 15,
            label: "Triangle"
        }
    ],

    giaIntensity: [
        {
            value: 1,
            label: "Faint"
        },
        {
            value: 2,
            label: "Very Light"
        },
        {
            value: 3,
            label: "Light"
        },
        {
            value: 4,
            label: "Fancy Light"
        },
        {
            value: 5,
            label: "Fancy"
        },
        {
            value: 6,
            label: "Fancy Intense"
        },
        {
            value: 7,
            label: "Fancy Vivid"
        },
        {
            value: 8,
            label: "Fancy Dark"
        },
        {
            value: 9,
            label: "Fancy Deep"
        }
    ],
    fluorescence: [
        {
            value: 1,
            label: "None"
        },
        {
            value: 2,
            label: "Faint"
        },
        {
            value: 3,
            label: "Medium"
        },
        {
            value: 4,
            label: "Strong"
        },
        {
            value: 5,
            label: "Very Strong"
        }],

    giaHueGrade: [
        {
            value: 1,
            label: "Pink"
        },
        {
            value: 2,
            label: "Orangy Pink"
        },
        {
            value: 3,
            label: "Pinkish Orange"
        },
        {
            value: 4,
            label: "Orange"
        },
        {
            value: 5,
            label: "Yellowish Orange"
        },
        {
            value: 6,
            label: "Yellow Orange"
        },
        {
            value: 7,
            label: "Orange Yellow"
        },
        {
            value: 8,
            label: "Orangy Yellow"
        },
        {
            value: 9,
            label: "Yellow"
        },
        {
            value: 10,
            label: "Greenish Yellow"
        },
        {
            value: 11,
            label: "Green Yellow"
        },
        {
            value: 12,
            label: "Yellow Green"
        },
        {
            value: 13,
            label: "Yellowish Green"
        },
        {
            value: 14,
            label: "Green"
        },
        {
            value: 15,
            label: "Bluish Green"
        },
        {
            value: 16,
            label: "Blue Green"
        },
        {
            value: 17,
            label: "Green Blue"
        },
        {
            value: 18,
            label: "Greenish Blue"
        },
        {
            value: 19,
            label: "Blue"
        },
        {
            value: 20,
            label: "Violetish Blue"
        },
        {
            value: 21,
            label: "Bluish Violet"
        },
        {
            value: 22,
            label: "Violet"
        },
        {
            value: 23,
            label: "Purple"
        },
        {
            value: 24,
            label: "Pinkish Purple"
        },
        {
            value: 25,
            label: "Pink Purple"
        },
        {
            value: 26,
            label: "Purple Pink"
        },
        {
            value: 27,
            label: "Purplish Pink"
        },
        {
            value: 28,
            label: "Red"
        },
        {
            value: 29,
            label: "Purplish Red"
        }
    ],
    weightCategory: [
        {
            value: 1,
            label: "0 - 0.49"
        },
        {
            value: 2,
            label: "0.5 - 0.99"
        },
        {
            value: 3,
            label: "1 - 1.49"
        },
        {
            value: 4,
            label: "1.5 - 1.99"
        },
        {
            value: 5,
            label: "2 - 2.49"
        },
        {
            value: 6,
            label: "2.5 - 2.99"
        },
        {
            value: 7,
            label: "3 - 4.99"
        },
        {
            value: 8,
            label: "5 - 7.99"
        },
        {
            value: 9,
            label: "8 - 9.99"
        },
        {
            value: 10,
            label: "10 - 19.99"
        },
        {
            value: 11,
            label: "20+"
        }
    ],
    group: [
        {
            value: 1,
            label: "Group 1"
        },
        {
            value: 2,
            label: "Group 2"
        },
        {
            value: 3,
            label: "Group 2+BD"
        },
        {
            value: 4,
            label: "BD"
        },
        {
            value: 5,
            label: "Group 3"
        },
        {
            value: 6,
            label: "Group 4"
        },
        {
            value: 7,
            label: "H3"
        },
        {
            value: 8,
            label: "Other"
        }
    ],
    recutResult: [
        {
            value: 0,
            label: "None"
        },
        {
            value: 1,
            label: "Very Light"
        },
        {
            value: 2,
            label: "Light"
        },
        {
            value: 3,
            label: "Fancy Light"
        },
        {
            value: 4,
            label: "Fancy"
        },
        {
            value: 5,
            label: "Fancy Intense"
        },{
            value: 6,
            label: "Fancy Vivid"
        },
        {
            value: 7,
            label: "Fancy Deep"
        },
        {
            value: 8,
            label: "Hue Upgrade"
        },
        {
            value: 9,
            label: "No Change"
        },
        {
            value: 10,
            label: "In Process"
        },
        {
            value: 11,
            label: "GIA"
        }
    ],
    fluorescenceHue: [
        {
            value: 1,
            label: "Blue"
        },
        {
            value: 2,
            label: "Yellow"
        },
        {
            value: 3,
            label: "Orange"
        },
        {
            value: 4,
            label: "Green"
        },
        {
            value: 5,
            label: "Other"
        }
    ],
    hueModifier: [
        {
            value: 1,
            label: "Empty"
        },
        {
            value: 2,
            label: "Brown"
        },
        {
            value: 3,
            label: "Brownish"
        },
        {
            value: 4,
            label: "Gray"
        },
        {
            value: 5,
            label: "Grayish"
        }
    ],
    hue: [
        {
            value: 1,
            label: "Red"
        },
        {
            value: 2,
            label: "Purplish Red"
        },
        {
            value: 3,
            label: "Orangy Red"
        },
        {
            value: 4,
            label: "Pink"
        },
        {
            value: 5,
            label: "Purplish Pink"
        },
        {
            value: 6,
            label: "Purple Pink"
        },
        {
            value: 7,
            label: "Pink Purple"
        },
        {
            value: 8,
            label: "Pinkish Purple"
        },
        {
            value: 9,
            label: "Purple"
        },
        {
            value: 10,
            label: "Violet"
        },
        {
            value: 11,
            label: "Grayish Violet"
        },
        {
            value: 12,
            label: "Gray Violet"
        },
        {
            value: 13,
            label: "Grayish Blue"
        },
        {
            value: 14,
            label: "Gray Blue"
        },
        {
            value: 15,
            label: "Violetish Blue"
        },
        {
            value: 16,
            label: "Bluish Violet"
        },
        {
            value: 17,
            label: "Blue"
        },
        {
            value: 18,
            label: "Greenish Blue"
        },
        {
            value: 19,
            label: "Green Blue"
        },
        {
            value: 20,
            label: "Blue Green"
        },
        {
            value: 21,
            label: "Bluish Green"
        },
        {
            value: 22,
            label: "Green"
        },
        {
            value: 23,
            label: "Yellowish Green"
        },
        {
            value: 24,
            label: "Yellow Green"
        },
        {
            value: 25,
            label: "Green Yellow"
        },
        {
            value: 26,
            label: "Greenish Yellow"
        },
        {
            value: 27,
            label: "Yellow"
        },
        {
            value: 28,
            label: "Orangy Yellow"
        },
        {
            value: 29,
            label: "Orange Yellow"
        },
        {
            value: 30,
            label: "Yellow Orange"
        },
        {
            value: 31,
            label: "Yellowish Orange"
        },
        {
            value: 32,
            label: "Orange"
        },
        {
            value: 33,
            label: "Pinkish Orange"
        },
        {
            value: 34,
            label: "Orangy Pink"
        },
        {
            value: 35,
            label: "Brownish Pink"
        },
        {
            value: 36,
            label: "Brown Pink"
        },
        {
            value: 37,
            label: "Brownish Yellow"
        },
        {
            value: 38,
            label: "Grayish Green"
        },
        {
            value: 39,
            label: "Brownish Orangy Pink"
        },
        {
            value: 40,
            label: "Pinkish Brown"
        },
        {
            value: 41,
            label: "Gray"
        }
    ]

}