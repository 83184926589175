import React, { useState, useEffect } from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Plot from "react-plotly.js";
import { Storage } from "aws-amplify";

const SpectrogramPlot = (props: any) => {
  const { path, width, height } = props;
  const [data, setData] = useState<any>([]);
  const [isUploaded, setIsUploaded] = useState(false);

  const customRequest = async ({ file, onSuccess, onError }: any) => {
    // console.log(file, 123);
    setIsUploaded(false);
    try {
      // Upload file to S3 using Amplify Storage
    //   console.log(8439);
      const result = await Storage.put(`${path}/spectrogram.txt`, file, {
        contentType: "text/plain",
        customPrefix: {
          public: "",
        },
      });

      console.log(result);

      message.success(`${file.name} file uploaded successfully`);
      onSuccess();
      setIsUploaded(true);
    } catch (error) {
      console.error("Error uploading file:", error);
      onError(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = await Storage.get(`${path}/spectrogram.txt`, {
          customPrefix: {
            public: "",
          },
        });
        const response = await fetch(url);
        const text = await response.text();

        // console.log(text)

        const lines = text.split("\n");
        const parsedData = lines
          .filter((line) => !line.startsWith("#"))
          .map((line) => line.split(",").map((x) => parseFloat(x)));

        if (parsedData.every((line) => line === null || Number.isNaN(line))) {
          console.log("All values in parsed data are null.");
          setData([]); // Set state to an empty array
          return;
        }

        //   console.log(parsedData)
        setData(parsedData);
      } catch (error) {
        console.error("Error fetching data from S3 bucket:", error);
      }
    };

    fetchData();
  }, [path, isUploaded]);

  return (
    <div>
      <Upload
        accept=".txt"
        maxCount={1}
        multiple={false}
        customRequest={customRequest}
        showUploadList={false}
        beforeUpload={() => true} // Allow automatic upload
      >
        <Button icon={<UploadOutlined />}>Upload File</Button>
      </Upload>

      {data.length > 0 ? (
        <Plot
          data={[
            {
              x: data.map((entry: any) => entry[0]),
              y: data.map((entry: any) => entry[1]),
              type: "scatter",
              mode: "lines",
              name: "S3 Data",
            },
          ]}
          layout={{
            title: "GemmoRaman Spectrum",
            xaxis: { title: "Wavelength (nm)" },
            yaxis: { title: "Absorbance" },
            width: width,
            height: height,
          }}
        />
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default SpectrogramPlot;
