import React, { useEffect, useState } from "react";
import {
  IResourceComponentsProps,
  setInitialFilters,
} from "@pankod/refine-core";
import {
  useTable,
  List,
  Table,
  Space,
  Button,
  Input,
  Icons,
  Select,
  DatePicker,
  Modal,
  Form,
  useModal,
  Checkbox,
  Divider,
  Tooltip,
  Spin,
} from "@pankod/refine-antd";

import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import { DiamondCreate } from "pages/forms/create";
import debounce from "lodash.debounce";
import { columns_, renderers } from "./table";
import { useResource } from "@pankod/refine-core";
import useExcelExport from "hooks/useExcelExport";

const rangePresets: {
  label: string;
  value: [Dayjs, Dayjs];
}[] = [
  {
    label: "Last 6 Months",
    value: [dayjs().add(-6, "M"), dayjs()],
  },
  {
    label: "Past Year",
    value: [dayjs().add(-1, "y"), dayjs()],
  },
  {
    label: "Last Two Years",
    value: [dayjs().add(-2, "y"), dayjs()],
  },
];

const {
  PlusOutlined,
  SearchOutlined,
  VerticalAlignBottomOutlined,
  FilterOutlined,
  FunnelPlotFilled,
  FunnelPlotOutlined,
} = Icons;

interface IPostList extends IResourceComponentsProps {
  canCreate?: boolean;
  sticky?: boolean;
  sl?: boolean;
  selected?: any;
  dataSource?: any;
  selectable?: boolean;
}

export const PostList: React.FC<IPostList> = ({
  canCreate = true,
  selectable = false,
  sticky = false,
  sl = false,
  selected = null,
}) => {
  const { tableProps, searchFormProps, setFilters, filters } = useTable({
    hasPagination: false,
    syncWithLocation: false,

    onSearch: (values: any) => {
      const { createdAt } = values;
      return [
        {
          field: "code",
          operator: "contains",
          value: values.q,
        },
        {
          field: "weight",
          operator: "eq",
          value: values.weight || undefined,
        },
        {
          field: "createdAt_",
          operator: "between",
          value: createdAt
            ? [
                createdAt[0].startOf("day").toISOString(),
                createdAt[1].endOf("day").toISOString(),
              ]
            : undefined,
        },
      ];
    },
  });

  const { modalProps, show: openCreateModal, close } = useModal();
  const localStr = localStorage.getItem("columns")?.split(",");
  const [selectedColumns, setSelectedColumns] = useState<any>(localStr || []);
  const [tableKey, setTableKey] = useState(0);
  const [tableFiltersActive, setTableFiltersActive] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [spinnerStartTime, setSpinnerStartTime] = useState<number | null>(null);
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);

  useEffect(() => {
    const searchFormValues = searchFormProps?.form?.getFieldsValue();
    const { q, createdAt } = searchFormValues;

    if (
      q ||
      createdAt ||
      (filters?.length !== 0 &&
        filters?.every((item) => item?.value?.length > 0))
    ) {
      setTableFiltersActive(true);
    } else {
      setTableFiltersActive(false);
    }
  }, [filters]);

  const resetFilters = () => {
    const emptyFilters: any = filters?.map((f) => {
      return { ...f, value: [] };
    });
    setFilters(emptyFilters);
    setTableKey((tableKey) => tableKey + 1);

    searchFormProps.form?.setFieldsValue({
      code: "",
      createdAt_: [],
    });
    searchFormProps.form?.resetFields();
    searchFormProps.form?.submit();
  };

  const tableDataSource: any = tableProps?.dataSource;
  const { generateExcel, isGenerating } = useExcelExport(tableDataSource);

  const { id } = useResource();

  const currentItem = tableDataSource?.find((item: any) => item.id === id);
  const currentItemSp: any = currentItem?.spectraScores;

  const columns = columns_(currentItemSp);

  useEffect(() => {
    console.log(currentItemSp);
    if (sl && !currentItemSp && spinnerStartTime === null) {
      setSpinnerStartTime(Date.now());
    } else if (currentItemSp && spinnerStartTime !== null) {
      const elapsedTime = (Date.now() - spinnerStartTime) / 1000;
      console.log(`Spinner loaded for ${elapsedTime} seconds`);
      setShowSpinner(false);
      setSpinnerStartTime(null);
    }
  }, [currentItemSp, sl, spinnerStartTime]);

  useEffect(() => {
    if (sl && !currentItemSp) {
      const spinnerTimeout = setTimeout(() => {
        setShowTimeoutModal(true);
      }, 20000); // Show modal if spinner loads for more than 20 seconds
      return () => clearTimeout(spinnerTimeout);
    }
  }, [currentItemSp, sl]);

  const handleReload = () => {
    window.location.reload();
  };

  const handleStopLoading = () => {
    setShowSpinner(false);
    setShowTimeoutModal(false);
  };

  const handleContinueLoading = () => {
    setShowTimeoutModal(false);
  };

  return (
    <List
      breadcrumb={null}
      headerProps={{
        style: {
          backgroundColor: "white",
          padding: "8px",
        },
        title: (
          <Space size={32}>
            <Tooltip placement="bottom" title="Reset all filters">
              <Button
                icon={<FilterOutlined />}
                type="default"
                disabled={!tableFiltersActive}
                onClick={() => resetFilters()}
              />
            </Tooltip>
            <Form {...searchFormProps} layout="inline">
              <Form.Item name="q">
                <Input
                  onChange={debounce(() => searchFormProps.form?.submit(), 500)}
                  style={{
                    width: "200px",
                  }}
                  placeholder="Code"
                  prefix={<SearchOutlined />}
                  allowClear
                />
              </Form.Item>
              <Form.Item name="weight">
                <Input
                  onChange={debounce(() => searchFormProps.form?.submit(), 500)}
                  style={{
                    width: "120px",
                  }}
                  placeholder="Weight"
                  prefix={<SearchOutlined />}
                  allowClear
                />
              </Form.Item>
            </Form>
          </Space>
        ),

        extra: (
          <Space>
            <Form {...searchFormProps} layout="inline">
              <Form.Item name="createdAt">
                <DatePicker.RangePicker
                  presets={rangePresets}
                  onChange={() => {
                    searchFormProps.form?.submit();
                  }}
                />
              </Form.Item>
            </Form>

            <Select
              value={selectedColumns}
              showSearch={false}
              showArrow
              maxTagCount={1}
              mode="multiple"
              optionLabelProp="title"
              virtual={false}
              fieldNames={{ label: "title", value: "key" }}
              options={columns.filter((col) => col.extended)}
              placeholder="Columns"
              style={{ width: "250px" }}
              onChange={(e) => {
                setSelectedColumns(e);
                localStorage.setItem("columns", e);
              }}
              dropdownRender={(menu) => (
                <>
                  <div style={{ padding: "4px" }}>
                    <Checkbox
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedColumns(
                            columns
                              .filter((col) => col.extended)
                              .map((col) => col.key)
                          );
                          localStorage.setItem(
                            "columns",
                            columns
                              .filter((col) => col.extended)
                              .map((col) => col.key)
                              .join(",")
                          );
                        } else {
                          setSelectedColumns([]);
                          localStorage.removeItem("columns");
                        }
                      }}
                    >
                      Select All
                    </Checkbox>
                  </div>
                  <Divider style={{ margin: "4px 0" }} />
                  {menu}
                </>
              )}
            />
            {canCreate && (
              <>
                <Tooltip placement="bottom" title="Export SL scores">
                  <Button
                    icon={<VerticalAlignBottomOutlined />}
                    type="default"
                    loading={isGenerating}
                    onClick={() => generateExcel()}
                  />
                </Tooltip>
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  onClick={(): void => openCreateModal()}
                >
                  Add New Diamond
                </Button>
              </>
            )}
          </Space>
        ),
      }}
    >
      {sl && !currentItemSp && showSpinner ? (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <Spin size="large" />
          <div style={{ marginTop: "10px" }}>Data is preparing...</div>
        </div>
      ) : (
        <Table
          key={tableKey}
          sticky={sticky}
          {...tableProps}
          dataSource={tableDataSource?.filter?.((d: any) => {
            if (selected && selected?.sl) {
              const parsed = JSON.parse(selected?.sl);
              if (parsed) return Object.keys(parsed).includes(d?.id);
            }
            return d?.id !== selected?.id;
          })}
          rowKey="id"
          size="large"
          pagination={{
            ...tableProps.pagination,
            position: ["bottomCenter"],
            pageSize: sl ? 100 : undefined,
            onChange: () => window.scrollTo(0, 0),
            size: "default",
          }}
          columns={columns
            .filter((col) => !col.extended || selectedColumns.includes(col.key))
            .filter((col) =>
              sl ? { ...col } : col.key !== "sl" && col.key !== "spectraScores"
            )}
          summary={(currentData) => (
            <Table.Summary fixed="top">
              <Table.Summary.Row>
                {selected && (
                  <React.Fragment>
                    {Object.entries(selected).map((entrie: any, index) => {
                      if (
                        !selectedColumns.includes(columns[index]?.key) &&
                        columns[index]?.extended
                      )
                        return "";

                      const render =
                        renderers(currentItemSp)[columns[index]?.key];

                      return (
                        <Table.Summary.Cell key={index} index={index}>
                          {render ? render(entrie[1], selected) : <></>}
                        </Table.Summary.Cell>
                      );
                    })}
                  </React.Fragment>
                )}
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      )}

      <Modal
        centered
        maskClosable={false}
        {...modalProps}
        style={{ minHeight: 600 }}
        footer={null}
        width={720}
        title={<strong>Add New Diamond</strong>}
        destroyOnClose
      >
        <DiamondCreate modalClose={close} />
      </Modal>

      <Modal
        title="Loading is too long..."
        visible={showTimeoutModal}
        onCancel={handleContinueLoading}
        footer={
          <Space direction="vertical">
            <Button
              key="reload"
              onClick={handleReload}
              style={{ width: 220 }}
              type="primary"
            >
              Reload the Page
            </Button>
            <Button
              key="stop"
              onClick={handleStopLoading}
              style={{ width: 220 }}
              type="primary"
            >
              Stop Loading
            </Button>
            <Button
              key="continue"
              onClick={handleContinueLoading}
              style={{ width: 220 }}
              type="primary"
            >
              Continue Loading
            </Button>
          </Space>
        }
      >
        <span>How do you want to proceed?</span>
      </Modal>
    </List>
  );
};
