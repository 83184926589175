import {
  ImageField,
  Space,
  Tooltip,
  Icons,
  ShowButton,
  DateField,
  TextField,
  FilterDropdown,
  Select,
  InputNumber,
  Slider,
  Input,
  Button,
  Spin,
} from "@pankod/refine-antd";
import data from "assets/data/columns.json";
import { definitions } from "assets/definitions";
import { API } from "aws-amplify";
import { getDiamond } from "graphql/queries";

import { nanoid } from "nanoid";

const { columns } = data;
const def: any = definitions;

function getLabel(name: string, value: number) {
  if (!value) return null;

  const item = def?.[name]?.find((obj: any) => obj.value === value);
  if (item) {
    return item.label;
  }
  return "-";
}

const { SnippetsTwoTone, SnippetsOutlined, FilterOutlined } = Icons;

export const renderers: any = (spValue: any) => {
  const currentURL = window.location.href;
  const match: any = currentURL.match(/\/([^/]+)$/);

  const renderWeightCategory = (value: any) => {
    const weightCategoryOptions = [
      { value: 1, label: "0 - 0.49" },
      { value: 2, label: "0.5 - 0.99" },
      { value: 3, label: "1 - 1.49" },
      { value: 4, label: "1.5 - 1.99" },
      { value: 5, label: "2 - 2.49" },
      { value: 6, label: "2.5 - 2.99" },
      { value: 7, label: "3 - 4.99" },
      { value: 8, label: "5 - 7.99" },
      { value: 9, label: "8 - 9.99" },
      { value: 10, label: "10 - 19.99" },
      { value: 11, label: "20+" },
    ];

    const numericValue = parseFloat(value);

    // Check for "20+"
    if (numericValue >= 20) {
      return "20+";
    }

    const selectedOption = weightCategoryOptions.find((option) => {
      const [start, end] = option.label.split(" - ").map(parseFloat);
      return numericValue >= start && numericValue <= end;
    });

    // console.log(selectedOption);

    return selectedOption ? selectedOption.label : "";
  };

  type RecordType = {
    spectraScores: string;
    sl: string;
  };

  const slCounters: { [key: string]: number } = {};

  const getSlCounter = (slValue: string): number => {
    if (!slCounters[slValue]) {
      slCounters[slValue] = 0;
    }
    slCounters[slValue]++;
    return slCounters[slValue];
  };

  const out = {
    code: (value: any, record: any) => (
      <div style={{ minWidth: "90px", width: "90px", maxWidth: "90px" }}>
        {record?.code}
      </div>
    ),
    officeCode: (value: any, record: any) => (
      <div style={{ minWidth: "90px", width: "90px", maxWidth: "90px" }}>
        {record?.officeCode}
      </div>
    ),
    sl: (value: any, record: any) => {
      if (match[1] && value?.sl) {
        const parsed = JSON.parse(value?.sl);
        const slValue: any = parsed[match[1]];

        return (
          <div style={{ minWidth: "40px", width: "40px", maxWidth: "40px" }}>
            {slValue || "-"}
          </div>
        );
      }

      return (
        <div style={{ minWidth: "40px", width: "40px", maxWidth: "40px" }}>
          -
        </div>
      );
    },
    spectraScores: (value: any, record: any, index: number) => {
      if (match[1] && value?.spectraScores) {
        const parsed = JSON.parse(value.spectraScores);
        const spValue: any = parsed[match[1]];

        const parsedSl = JSON.parse(value.sl);
        const slValue = parsedSl[match[1]];

        if (!slValue || !spValue) {
          return (
            <div style={{ minWidth: "40px", width: "40px", maxWidth: "40px" }}>
              -
            </div>
          );
        }

        // const isSameSl = spValue?.[0] === slValue;

        const slCounter = getSlCounter(slValue);

        const displayValue = `(${spValue?.[0]}-${slCounter})`;

        if (spValue && slCounter && displayValue) {
          return (
            <div style={{ minWidth: "50px", width: "50px", maxWidth: "50px" }}>
              {displayValue}
            </div>
          );
        } else {
          return (
            <div
              style={{
                minWidth: "50px",
                width: "50px",
                maxWidth: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin />
            </div>
          );
        }
      }

      return (
        <div style={{ minWidth: "40px", width: "40px", maxWidth: "40px" }}>
          -
        </div>
      );
    },
    status: (value: any, record: any) => (
      <div
        style={{
          minWidth: "20px",
          width: "20px",
          maxWidth: "20px",
          paddingLeft: 16,
        }}
      >
        {record?.sl !== "{}" ? (
          <div className="active-circle" />
        ) : (
          <div className="inactive-circle" />
        )}
      </div>
    ),
    image: (value: any, record: any) => {
      return (
        <ImageField
          style={{
            width: "60px",
            height: "60px",
            maxWidth: "60px",
            minWidth: "60px",
          }}
          value={`https://papushado-storage-amp105130-dev.s3.amazonaws.com/diamonds/${record?.image}`}
          key={nanoid()}
        />
      );
    },

    shape: (value: any, record: any) => (
      <div style={{ whiteSpace: "nowrap", minWidth: 90 }}>
        {getLabel("shape", record?.shape)}
      </div>
    ),
    weight: (value: any, record: any) => (
      <div style={{ whiteSpace: "nowrap", minWidth: 90 }}>{record?.weight}</div>
    ),
    weightCategory: (value: any, record: any) => (
      <div style={{ whiteSpace: "nowrap", minWidth: 110 }}>
        {/* {renderWeightCategory(record?.weight) } */}
        {getLabel("weightCategory", record?.weightCategory)}
      </div>
    ),
    giaIntensity: (value: any, record: any) => (
      <div style={{ whiteSpace: "nowrap", minWidth: 120 }}>
        {getLabel("giaIntensity", record?.giaIntensity)}
      </div>
    ),
    giaHueGrade: (value: any, record: any) => (
      <div style={{ whiteSpace: "nowrap", minWidth: 120 }}>
        {getLabel("giaHueGrade", record?.giaHueGrade)}
      </div>
    ),
    hueModifier: (value: any, record: any) => (
      <div style={{ whiteSpace: "nowrap", minWidth: 120 }}>
        {getLabel("hueModifier", record?.hueModifier)}
      </div>
    ),
    fluorescence: (value: any, record: any) => (
      <div style={{ whiteSpace: "nowrap", minWidth: 120 }}>
        {getLabel("fluorescence", record?.fluorescence)}
      </div>
    ),
    scale: (value: any, record: any) => (
      <div style={{ whiteSpace: "nowrap", minWidth: 90 }}>{record?.scale}</div>
    ),
    recutResult: (value: any, record: any) => (
      <div style={{ whiteSpace: "nowrap", minWidth: 120 }}>
        {getLabel("recutResult", record?.recutResult)}
      </div>
    ),
    group: (value: any, record: any) => (
      <div style={{ whiteSpace: "nowrap", minWidth: 90 }}>
        {getLabel("group", record?.group)}
      </div>
    ),
    giaReportNumber: (value: any, record: any) => (
      <div style={{ whiteSpace: "nowrap", minWidth: 120 }}>
        {record?.giaReportNumber}
      </div>
    ),
    table: (value: any, record: any) => (
      <div style={{ whiteSpace: "nowrap", minWidth: 90 }}>
        {record?.table ? `${record?.table}%` : "-"}
      </div>
    ),
    depth: (value: any, record: any) => (
      <div style={{ whiteSpace: "nowrap", minWidth: 100 }}>
        {record.depth ? `${record?.depth}%` : "-"}
      </div>
    ),
    strong: (value: any, record: any) => (
      <div style={{ whiteSpace: "nowrap", minWidth: 90 }}>
        {record?.strong ? `${record?.strong}%` : "-"}
      </div>
    ),
    weak: (value: any, record: any) => (
      <div style={{ whiteSpace: "nowrap", minWidth: 90 }}>
        {record?.weak ? `${record?.weak}%` : "-"}
      </div>
    ),
    medium: (value: any, record: any) => (
      <div style={{ whiteSpace: "nowrap", minWidth: 90 }}>
        {record?.medium ? `${record?.medium}%` : "-"}
      </div>
    ),
    unclassified: (value: any, record: any) => (
      <div style={{ whiteSpace: "nowrap", minWidth: 120 }}>
        {record?.unclassified ? `${record?.unclassified}%` : "-"}
      </div>
    ),
    action: (value: any, record: any) => {
      return (
        // <div style={{  minWidth:"160px", maxWidth:"160px" }}>
        <Space size="large" align="center">
          <ShowButton
            recordItemId={record?.id}
            type="primary"
            ghost
            icon={null}
            style={{ width: "100%" }}
          >
            View/Compare
          </ShowButton>

          {record?.description ? (
            <Tooltip
              placement="left"
              title={
                <Space direction="vertical">
                  <span style={{ fontSize: "12px" }}>
                    {`Reviewed on   `}{" "}
                    <DateField
                      value={record?.updateddAt}
                      style={{ color: "#fff" }}
                    />
                  </span>

                  {record?.description}

                  <span style={{ fontSize: "12px" }}>
                    {`Reviewed by   `}{" "}
                    <TextField value={record?.user} style={{ color: "#fff" }} />
                  </span>
                </Space>
              }
            >
              {record?.status !== "draft" && (
                <SnippetsTwoTone style={{ fontSize: 22 }} />
              )}
            </Tooltip>
          ) : (
            <SnippetsOutlined style={{ fontSize: 22, opacity: 0.2 }} />
          )}
        </Space>
      );
    },
  };

  if (spValue && spValue === "{}") {
    out.spectraScores = () => (
      <div style={{ minWidth: "40px", width: "40px", maxWidth: "40px" }}>-</div>
    );
    //  return out;
  }

  return out;
};

export const columns_ = (currentItemSp: any) => {
  return columns.map((col: any) => {
    const excludeFilters = [
      "image",
      "code",
      "status",
      "action",
      "giaReportNumber",
      "sl",
      "spectraScores",
      "officeCode",
    ];
    const excludeSorters =
      currentItemSp && currentItemSp !== "{}"
        ? ["image", "status", "action", "spectraScores"]
        : ["image", "status", "action"];

    const percentageFilters = [
      "weak",
      "medium",
      "strong",
      "table",
      "depth",
      "unclassified",
      // "scale",
    ];

    col.render = renderers(currentItemSp)[col?.key];

    col.align = col?.key !== "action" ? "center" : "undefined";

    if (!excludeFilters.includes(col?.key)) {
      if (col?.key === "scale") {
        col.filterDropdown = (props: any) => {
          const {
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
          } = props;

          const isInputValid =
            selectedKeys[0] !== undefined && selectedKeys[1] !== undefined;

          return (
            <div style={{ padding: 8 }}>
              <InputNumber
                min={0}
                max={50}
                placeholder="Min Scale"
                style={{ marginBottom: 8, display: "block", width: "115px" }}
                value={selectedKeys[0]}
                onChange={(value) => setSelectedKeys([value, selectedKeys[1]])}
              />
              <InputNumber
                min={0}
                max={50}
                placeholder="Max Scale"
                style={{ marginBottom: 8, display: "block", width: "115px" }}
                value={selectedKeys[1]}
                onChange={(value) => setSelectedKeys([selectedKeys[0], value])}
              />
              <Button
                type="primary"
                onClick={() => {
                  if (isInputValid) {
                    confirm();
                  } else {
                    // Add a message or alert to inform the user about the validation error
                    alert("Both inputs must be filled.");
                  }
                }}
                size="small"
                icon={<FilterOutlined />}
                style={{ marginRight: 8 }}
              >
                Filter
              </Button>
              <Button
                danger
                onClick={() => {
                  clearFilters();
                  setSelectedKeys([0, 50]);
                  confirm();
                  setSelectedKeys([]);
                  close(); // Reset the selected keys to the default range
                }}
                size="small"
              >
                Clear
              </Button>
            </div>
          );
        };
      } else if (col?.key === "weight") {
        col.filterDropdown = (props: any) => {
          const {
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
          } = props;

          const isInputValid =
            selectedKeys[0] !== undefined && selectedKeys[1] !== undefined;

          return (
            <div style={{ padding: 8 }}>
              <InputNumber
                min={0}
                max={50}
                step={0.1}
                placeholder="Min Weight"
                style={{ marginBottom: 8, display: "block", width: "115px" }}
                value={selectedKeys[0]}
                onChange={(value) => setSelectedKeys([value, selectedKeys[1]])}
              />
              <InputNumber
                min={0}
                max={50}
                step={0.1}
                placeholder="Max Weight"
                style={{ marginBottom: 8, display: "block", width: "115px" }}
                value={selectedKeys[1]}
                onChange={(value) => setSelectedKeys([selectedKeys[0], value])}
              />
              <Button
                type="primary"
                onClick={() => {
                  if (isInputValid) {
                    confirm();
                  } else {
                    // Add a message or alert to inform the user about the validation error
                    alert("Both inputs must be filled.");
                  }
                }}
                size="small"
                icon={<FilterOutlined />}
                style={{ marginRight: 8 }}
              >
                Filter
              </Button>
              <Button
                danger
                onClick={() => {
                  clearFilters();
                  setSelectedKeys([0, 50]);
                  confirm();
                  setSelectedKeys([]);
                  close(); // Reset the selected keys to the default range
                }}
                size="small"
              >
                Clear
              </Button>
            </div>
          );
        };
      } else if (percentageFilters.includes(col.key)) {
        col.filterDropdown = (props: any) => {
          return (
            <FilterDropdown {...props}>
              <InputNumber min={0} max={100} style={{ width: "120px" }} />
            </FilterDropdown>
          );
        };
      } else {
        col.filterDropdown = (props: any) => {
          return (
            <FilterDropdown {...props}>
              <Select
                optionFilterProp="label"
                style={{ width: "160px" }}
                mode="multiple"
                placeholder="Select Category"
                options={def[col?.key]}
              />
            </FilterDropdown>
          );
        };
      }
    }

    if (!excludeSorters.includes(col?.key)) {
      col.sorter = (a: any, b: any) => {
        return a[col?.key] - b[col?.key];
      };
    }

    if (col?.key === "code") {
      col.sorter = (a: any, b: any) => a.code.localeCompare(b.code);
    }

    if (col?.key === "sl") {
      const currentURL = window.location.href;
      const match: any = currentURL.match(/\/([^/]+)$/);
      col.sorter = (a: any, b: any) => {
        if (!a.sl || !b.sl) return;
        const parsedDataA = JSON.parse(a?.sl)?.[match[1]];
        const parsedDataB = JSON.parse(b?.sl)?.[match[1]];

        return Number(parsedDataA) - Number(parsedDataB);
      };
      col.defaultSortOrder = "ascend";
    }

    if (currentItemSp && currentItemSp === "{}") return col;
    if (col?.key === "spectraScores") {
      if (currentItemSp && currentItemSp === "{}") {
        col.sorter = () => 0;
        col.defaultSortOrder = undefined;
        return col;
      }

      const currentURL = window.location.href;
      const match = currentURL.match(/\/([^/]+)$/);

      if (match && match[1]) {
        const urlParam = match[1];

        col.sorter = (a: any, b: any) => {
          if (!a.spectraScores || !b.spectraScores) return 0;

          let parsedDataA, parsedDataB;
          try {
            parsedDataA = JSON.parse(a.spectraScores)?.[urlParam];
            parsedDataB = JSON.parse(b.spectraScores)?.[urlParam];
          } catch (error) {
            console.error("Error parsing spectraScores:", error);
            return 0;
          }

          if (!parsedDataA || !parsedDataB) {
            console.warn(
              "Parsed data is missing for:",
              !parsedDataA ? "A" : "B"
            );
            return 0;
          }

          // Handle missing elements by placing them last
          if (!parsedDataA[1]) return 1;
          if (!parsedDataB[1]) return -1;

          // Compare the first elements
          if (parsedDataA[0] !== parsedDataB[0]) {
            return parsedDataA[0] - parsedDataB[0];
          }

          // Compare the second elements if the first elements are equal
          if (parsedDataA[1] !== parsedDataB[1]) {
            return parsedDataA[1] - parsedDataB[1];
          }

          // Compare the third elements if the first and second elements are equal
          return parsedDataA[2] - parsedDataB[2];
        };

        col.defaultSortOrder = "ascend"
          // currentItemSp && currentItemSp !== "{}" ? "ascend" : undefined;
      } else {
        console.error("URL parameter not found");
      }
    }

    return col;
  });
};
