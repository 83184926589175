/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createDiamond = /* GraphQL */ `mutation CreateDiamond(
  $input: CreateDiamondInput!
  $condition: ModelDiamondConditionInput
) {
  createDiamond(input: $input, condition: $condition) {
    id
    code
    officeCode
    description
    status
    image
    video
    spectrogram
    sl
    spectraScores
    shape
    scale
    weight
    giaIntensity
    hueModifier
    giaHueGrade
    fluorescence
    fluorescenceHue
    recutResult
    giaReportNumber
    weightCategory
    group
    depth
    table
    strong
    medium
    weak
    unclassified
    user
    createdAt_
    hue
    hasPairs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDiamondMutationVariables,
  APITypes.CreateDiamondMutation
>;
export const updateDiamond = /* GraphQL */ `mutation UpdateDiamond(
  $input: UpdateDiamondInput!
  $condition: ModelDiamondConditionInput
) {
  updateDiamond(input: $input, condition: $condition) {
    id
    code
    officeCode
    description
    status
    image
    video
    spectrogram
    sl
    spectraScores
    shape
    scale
    weight
    giaIntensity
    hueModifier
    giaHueGrade
    fluorescence
    fluorescenceHue
    recutResult
    giaReportNumber
    weightCategory
    group
    depth
    table
    strong
    medium
    weak
    unclassified
    user
    createdAt_
    hue
    hasPairs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDiamondMutationVariables,
  APITypes.UpdateDiamondMutation
>;
export const deleteDiamond = /* GraphQL */ `mutation DeleteDiamond(
  $input: DeleteDiamondInput!
  $condition: ModelDiamondConditionInput
) {
  deleteDiamond(input: $input, condition: $condition) {
    id
    code
    officeCode
    description
    status
    image
    video
    spectrogram
    sl
    spectraScores
    shape
    scale
    weight
    giaIntensity
    hueModifier
    giaHueGrade
    fluorescence
    fluorescenceHue
    recutResult
    giaReportNumber
    weightCategory
    group
    depth
    table
    strong
    medium
    weak
    unclassified
    user
    createdAt_
    hue
    hasPairs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDiamondMutationVariables,
  APITypes.DeleteDiamondMutation
>;
