import React, { FC, useContext, useEffect, useState } from "react";
import { definitions } from "assets/definitions";

import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Icons,
  InputNumber,
} from "@pankod/refine-antd";

interface IBasicInformationProps {
  patientID?: string;
  isFullScreen?: boolean;
  form?: any;
}

const { InfoCircleOutlined } = Icons;

export const EditForm: FC<IBasicInformationProps> = (props) => {
  const [fluorescence, setFluorence] = useState<any>(null);
  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Weight" name="weight">
            <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="GIA Intensity" name="giaIntensity">
            <Select options={definitions["giaIntensity"]} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Hue Modifier" name="hueModifier">
            <Select options={definitions["hueModifier"]} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="GIA Hue Grade" name="giaHueGrade">
            <Select
              options={definitions["giaHueGrade"]}
              showSearch
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Fluorescence" name="fluorescence">
            <Select
              options={definitions["fluorescence"]}
              onSelect={(e) => setFluorence(e)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Fluorescence Hue" name="fluorescenceHue">
            <Select
              options={definitions["fluorescenceHue"]}
              disabled={!fluorescence || fluorescence === 1}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="GIA Report Number" name="giaReportNumber">
            <Input maxLength={30} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Office code" name="officeCode">
            <Input maxLength={50} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Group" name="group">
            <Select options={definitions["group"]} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Depth" name="depth">
            <InputNumber min={0} max={100} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Table" name="table">
            <InputNumber min={0} max={100} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Strong" name="strong">
            <InputNumber min={0} max={100} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Medium" name="medium">
            <InputNumber min={0} max={100} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Weak" name="weak">
            <InputNumber min={0} max={100} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Unclassified" name="unclassified">
            <InputNumber min={0} max={100} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
