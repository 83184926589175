import React, { FC, useEffect, useState } from "react";
import {
  AntdLayout,
  Space,
  Menu,
  Button,
  Icons,
  Dropdown,
  Avatar,
  Typography,
  Modal,
  Form,
  useModal,
  message,
  notification,
  Badge,
  Popover,
  TextField,
  Table,
  Progress,
  Timeline,
  Collapse,
} from "@pankod/refine-antd";
import { useCreate, useGetIdentity, useLogout } from "@pankod/refine-core";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLSubscription } from "@aws-amplify/api";

import "../../../style.css";
import NoticeList from "components/noticeList";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { onUpdateDiamond } from "graphql/subscriptions";
import { OnUpdateDiamondSubscription } from "API";
import SLDisplay from "components/slDisplay";
import { useNavigation } from "@pankod/refine-core";

const { DownOutlined, UserOutlined, LogoutOutlined, BellOutlined } = Icons;
const { Text } = Typography;
const { Panel } = Collapse;

const items = [{ label: "Logout", key: "logout", icon: <LogoutOutlined /> }];

interface IHeaderProps {
  user?: any;
  signout?: any;
}

const transformDataSteps: string[] = [
  "start",
  "createPairs",
  "extractFrames",
  "extractFeatures",
  "runModel",
  "getResults",
  "finish",
];

const transformDataSteps2: { value: string; label: string }[] = [
  { value: "start", label: "Start" },
  { value: "createPairs", label: "Pairs creation" },
  { value: "extractFrames", label: "Frames extraction" },
  { value: "extractFeatures", label: "Features and calculations" },
  { value: "runModel", label: "Model start running" },
  { value: "getResults", label: "Getting results" },
  { value: "finish", label: "Finish" },
];

export const Header: FC<IHeaderProps> = (props) => {
  const { show } = useNavigation();
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const [latestNotice, setLatestNotice] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [badgeCount, setBadgeCount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [currentId, setCurrentId] = useState<any>(null);

  const onMenuItemClick = (event: any) => {
    const { key } = event;
    console.log(key);
    if (key === "logout") {
      signOut({ global: true });
    }

    if (key === "export") {
      // Add your export logic if needed
    }
  };

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    const subscription = API.graphql<
      GraphQLSubscription<OnUpdateDiamondSubscription>
    >(graphqlOperation(onUpdateDiamond)).subscribe({
      next: ({ provider, value }) => {
        const data: any = value.data?.onUpdateDiamond;
        setCurrentId(data?.id);

        if (!data?.status || !transformDataSteps.includes(data?.status)) {
          return () => subscription.unsubscribe();
        }

        if (data?.status === "pairsError") {
          return () => subscription.unsubscribe();
        }

        const stepIndex = transformDataSteps.indexOf(data?.status);
        const newProgress = Math.round(
          ((stepIndex + 1) / transformDataSteps.length) * 100
        );

        setProgress(newProgress);

        if (currentId === data?.id) {
          console.log({ currentId });
          const notifier: any = handleNotifications(data?.id, data?.status);
          type NotificationType = "success" | "info" | "warning" | "error";

          const type: NotificationType = notifier?.status;

          notification[type]({
            message: notifier?.message,
          });
        }

        if (data?.status === "start") {
          setBadgeCount((prevCount) => prevCount + 1);
          setLatestNotice(data);

          console.log(data, "start", currentId);
        }

        if (data?.status === "pairsError") {
          notification.error({
            message: "No pairs",
          });
        }

        if (data?.status === "finish") {
          // console.log(currentId, 2222222, data?.id);
          if (currentId === data?.id) {
            setLatestNotice(data);
            console.log(data, "finish");
          }
        }
      },
      error: (error) => console.warn(error),
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <AntdLayout.Header
      className="dd"
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "16px 32px",
        width: "100%",
        top: 0,
        backgroundColor: "#F9FAFC",
        borderBottom: "1px solid #ECEEF0",
        zIndex: 1000,
        overflow: "hidden",
      }}
    >
      <div style={{ width: "100%" }}>
        <a href="/">
          <img
            src={require("../../../assets/images/logo.png")}
            alt="Papushado"
            style={{
              width: "200px",
              padding: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              float: "left",
              zIndex: 1000,
            }}
          />
        </a>
      </div>

      <Space size="large">
        <Popover
          overlayInnerStyle={{ padding: 0 }}
          arrow={false}
          placement="bottomRight"
          trigger="click"
          open={open}
          onOpenChange={handleOpenChange}
          content={
            <div>
              <Collapse
                style={{
                  width: 460,
                  maxHeight: latestNotice ? 500 : 0,
                  overflow: "scroll",
                }}
              >
                {latestNotice && (
                  <Panel
                    header={`Diamond ${latestNotice.id} - progress: ${progress}%`}
                    key={latestNotice.id}
                  >
                    <Space size="large">
                      <Timeline>
                        {transformDataSteps2.map((step: any, index: any) => (
                          <Timeline.Item
                            key={index}
                            color={
                              progress >=
                              index * (100 / transformDataSteps.length)
                                ? "green"
                                : "gray"
                            }
                          >
                            {step.label}
                          </Timeline.Item>
                        ))}
                      </Timeline>
                      <Progress
                        type="circle"
                        width={200}
                        percent={progress}
                        status="active"
                        strokeColor={progress === 100 ? "#52C41A" : "#1890FF"}
                      />
                    </Space>
                    {progress === 100 && (
                      <div style={{ textAlign: "center" }}>
                        <Button
                          type="primary"
                          style={{ width: 240 }}
                          onClick={() => {
                            console.log(progress);
                            show("diamonds", latestNotice.id);
                            hide();
                            setBadgeCount(0);
                            setLatestNotice(null);
                          }}
                        >
                          Go to diamond
                        </Button>
                      </div>
                    )}
                  </Panel>
                )}
              </Collapse>
            </div>
          }
        >
          <div style={{ cursor: "pointer" }}>
            <Badge count={latestNotice ? 1 : 0} color="#84BEED">
              <Avatar
                shape="square"
                icon={<BellOutlined />}
                style={{ backgroundColor: "transparent", color: "#000" }}
              />
            </Badge>
          </div>
        </Popover>

        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu
              items={items}
              onClick={onMenuItemClick}
              style={{ marginTop: 8 }}
            />
          }
        >
          <Space style={{ cursor: "pointer" }}>
            <Avatar style={{ backgroundColor: "#0A7DDC1A", color: "#0A7DDC" }}>
              <UserOutlined onClick={() => console.log(user)} />
            </Avatar>
            <span>{user.attributes?.email}</span>

            <DownOutlined />
          </Space>
        </Dropdown>
      </Space>
    </AntdLayout.Header>
  );
};

const handleNotifications = (id: any, status: any) => {
  const data = [
    {
      value: "start",
      message: `Analysis for diamond ${id} has just started`,
      status: "info",
    },
    {
      value: "pairsError",
      message: `There are no pairs for diamond ${id}`,
      status: "error",
    },
    {
      value: "createPairs",
      message: `Pairs creation for diamond ${id} is done!`,
      status: "success",
    },
    {
      value: "extractFrames",
      message: `Frames extraction for diamond ${id} is done!`,
      status: "success",
    },
    {
      value: "extractFeatures",
      message: `Features extraction and calculations for diamond ${id} is done!`,
      status: "success",
    },
    { value: "runModel", message: `Model is running...`, status: "info" },
    {
      value: "getResults",
      message: `Getting results for diamond ${id}`,
      status: "info",
    },
    {
      value: "finish",
      message: `Analysis for diamond ${id} is done!`,
      status: "success",
    },
  ];

  const notification = data.find((item: any) => item.value === status);

  return {
    message: notification?.message,
    status: notification?.status,
  };
};
