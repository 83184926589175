import React, { FC, useContext, useEffect, useState } from "react";
import { definitions } from "assets/definitions";

import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Icons,
  InputNumber,
} from "@pankod/refine-antd";
import { useOne, useShow } from "@pankod/refine-core";
import debounce from "lodash.debounce";

interface IBasicInformationProps {
  patientID?: string;
  isFullScreen?: boolean;
  form?: any;
}

const { InfoCircleOutlined } = Icons;

export const BasicInformation: FC<IBasicInformationProps> = (props) => {
  const [fluorescence, setFluorence] = useState<any>(null);
  const [code, setCode] = useState("");

  const { queryResult } = useShow({
    id: code,
    errorNotification: false,
    successNotification: false,
    queryOptions: {
      retry: 100,
      enabled: true,
  },
  });
  const { data } = queryResult;
  const record = data?.data;

  const validateCode = [
    {
      required: true,
      message: "Code field is required",
    },
    {
      validator() {
        if (code !== record?.id) {
          return Promise.resolve();
        }

        return Promise.reject();
      },
      message: "Code already exist",
    },
  ];

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Code"
            name="code"
            rules={[...validateCode]}
            validateTrigger={["onSubmit", "onBlur"]}
          >
            <Input
              maxLength={20}
              onChange={debounce((e) => setCode(e.target.value), 500)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Hue"
            name="hue"
            rules={[
              {
                required: true,
                message: "Hue is required",
              },
            ]}
          >
            <Select options={definitions["hue"]} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Scale"
            name="scale"
            rules={[
              {
                required: true,
                message: "Scale field is required",
              },
            ]}
          >
            <InputNumber min={1} max={27} step={1} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Shape"
            name="shape"
            rules={[
              {
                required: true,
                message: "Shape field is required",
              },
            ]}
          >
            <Select options={definitions["shape"]} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Weight" name="weight">
            <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="GIA Intensity" name="giaIntensity">
            <Select options={definitions["giaIntensity"]} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Hue Modifier" name="hueModifier">
            <Select options={definitions["hueModifier"]} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="GIA Hue Grade" name="giaHueGrade">
            <Select
              options={definitions["giaHueGrade"]}
              showSearch
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Fluorescence" name="fluorescence">
            <Select
              options={definitions["fluorescence"]}
              onSelect={(e) => setFluorence(e)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Fluorescence Hue" name="fluorescenceHue">
            <Select
              options={definitions["fluorescenceHue"]}
              disabled={!fluorescence || fluorescence === 1}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
