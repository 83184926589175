/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getDiamond = /* GraphQL */ `query GetDiamond($id: ID!) {
  getDiamond(id: $id) {
    id
    code
    officeCode
    description
    status
    image
    video
    spectrogram
    sl
    spectraScores
    shape
    scale
    weight
    giaIntensity
    hueModifier
    giaHueGrade
    fluorescence
    fluorescenceHue
    recutResult
    giaReportNumber
    weightCategory
    group
    depth
    table
    strong
    medium
    weak
    unclassified
    user
    createdAt_
    hue
    hasPairs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDiamondQueryVariables,
  APITypes.GetDiamondQuery
>;
export const listDiamonds = /* GraphQL */ `query ListDiamonds(
  $filter: ModelDiamondFilterInput
  $limit: Int
  $nextToken: String
) {
  listDiamonds(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      code
      officeCode
      description
      status
      image
      video
      spectrogram
      sl
      spectraScores
      shape
      scale
      weight
      giaIntensity
      hueModifier
      giaHueGrade
      fluorescence
      fluorescenceHue
      recutResult
      giaReportNumber
      weightCategory
      group
      depth
      table
      strong
      medium
      weak
      unclassified
      user
      createdAt_
      hue
      hasPairs
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDiamondsQueryVariables,
  APITypes.ListDiamondsQuery
>;
