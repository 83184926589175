import { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { definitions } from "assets/definitions";
import { useList } from "@pankod/refine-core";

const def: any = definitions;

function getLabel(name: string, value: number) {
  if (!value) return null;

  const item = def?.[name]?.find((obj: any) => obj.value === value);
  if (item) {
    return item.label;
  }
  return "-";
}

interface AntdTableItem {
  code: string;
  sl: { [key: string]: number };
}

interface TransformedDataItem {
  pair1: string;
  pair2: string;
  sl: number;
}

interface UseExcelExportProps {
  antdTableData: AntdTableItem[];
}

interface UseExcelExportReturn {
  generateExcel: () => void;
  isGenerating: boolean;
}

const useExcelExport = (antdTableData: any) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [result, setResult] = useState<any>([]);

  const { data, isLoading, isError } = useList({
    resource: "diamonds",
  });

  const diamonds = data?.data ?? [];

  const getItem = (code: any) =>
    diamonds?.find((item: any) => item?.code === code);

  useEffect(() => {
    console.log("data source")
    setIsGenerating(false);
    const result_: any = [];
    if (antdTableData) {
      antdTableData.forEach((item: any) => {
        const code = item.code;
        const color1 = getLabel("hue", item?.hue);
        const shape1 = getLabel("shape", item?.shape);
        const scale1 = item.scale;
        // console.log(code)

        const sl = JSON.parse(item?.sl);

        if (sl) {
          Object.keys(sl).forEach((pair) => {
            const pair1 = code;
            const pair2 = pair;
            const slValue = sl[pair];

            const pair1_scale = scale1;
            const pair1_color = color1;
            const pair1_shape = shape1;

            // const { shape: shape2, hue: hue2 } = getItem?.(pair);

            const pair2_scale = getItem?.(pair)?.scale;
            const pair2_color = getLabel?.("hue", getItem?.(pair)?.hue);
            const pair2_shape = getLabel?.("shape", getItem?.(pair)?.shape);

            if (
              pair.includes("test") ||
              pair.includes("omts") ||
              pair.includes("check") ||
              pair.startsWith("_")
            ) {
              return;
            }

            result_.push({
              pair1,
              pair2,
              pair1_scale,
              pair1_shape,
              pair1_color,
              pair2_scale,
              pair2_shape,
              pair2_color,
              sl: slValue,
            });
            setResult(result_);
          });
        }
      });
    }
  }, [antdTableData]);

  const transformData = (
    dataSource: AntdTableItem[]
  ): TransformedDataItem[] => {
    const result_: TransformedDataItem[] = [];

    dataSource.forEach((item) => {
      const code = item.code;
  
      const sl = item.sl;

      Object.keys(sl).forEach((pair) => {
        const pair1 = code;
        const pair2 = pair;
        const slValue = sl[pair];

        result_.push({ pair1, pair2, sl: slValue });
        setResult(result_);
      });
    });

    return result;
  };

  const generateExcel = () => {
    if (!antdTableData || antdTableData.length === 0) {
      console.error("Data source is empty or not loaded.");
      return;
    }

    setIsGenerating(true);

    // Perform data transformation
    // const transformedData = transformData(antdTableData);

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Add a worksheet
    const ws = XLSX.utils.json_to_sheet(result);

    ws["!cols"] = [
      { wch: 15 },
      { wch: 15 },
      { wch: 11 },
      { wch: 15 },
      { wch: 15 },
      { wch: 11 },
      { wch: 15 },
      { wch: 15 },
      { wch: 5 },
    ];

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    // Save the workbook to a file
    XLSX.writeFile(wb, "sl_scores.xlsx");

    setIsGenerating(false);
  };

  return {
    generateExcel,
    isGenerating,
  };
};

export default useExcelExport;
