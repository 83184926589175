/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateDiamond = /* GraphQL */ `subscription OnCreateDiamond($filter: ModelSubscriptionDiamondFilterInput) {
  onCreateDiamond(filter: $filter) {
    id
    code
    officeCode
    description
    status
    image
    video
    spectrogram
    sl
    spectraScores
    shape
    scale
    weight
    giaIntensity
    hueModifier
    giaHueGrade
    fluorescence
    fluorescenceHue
    recutResult
    giaReportNumber
    weightCategory
    group
    depth
    table
    strong
    medium
    weak
    unclassified
    user
    createdAt_
    hue
    hasPairs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDiamondSubscriptionVariables,
  APITypes.OnCreateDiamondSubscription
>;
export const onUpdateDiamond = /* GraphQL */ `subscription OnUpdateDiamond($filter: ModelSubscriptionDiamondFilterInput) {
  onUpdateDiamond(filter: $filter) {
    id
    code
    officeCode
    description
    status
    image
    video
    spectrogram
    sl
    spectraScores
    shape
    scale
    weight
    giaIntensity
    hueModifier
    giaHueGrade
    fluorescence
    fluorescenceHue
    recutResult
    giaReportNumber
    weightCategory
    group
    depth
    table
    strong
    medium
    weak
    unclassified
    user
    createdAt_
    hue
    hasPairs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDiamondSubscriptionVariables,
  APITypes.OnUpdateDiamondSubscription
>;
export const onDeleteDiamond = /* GraphQL */ `subscription OnDeleteDiamond($filter: ModelSubscriptionDiamondFilterInput) {
  onDeleteDiamond(filter: $filter) {
    id
    code
    officeCode
    description
    status
    image
    video
    spectrogram
    sl
    spectraScores
    shape
    scale
    weight
    giaIntensity
    hueModifier
    giaHueGrade
    fluorescence
    fluorescenceHue
    recutResult
    giaReportNumber
    weightCategory
    group
    depth
    table
    strong
    medium
    weak
    unclassified
    user
    createdAt_
    hue
    hasPairs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDiamondSubscriptionVariables,
  APITypes.OnDeleteDiamondSubscription
>;
