import React, { FC } from "react";
import { definitions } from "assets/definitions";

import {
  Form,
  Input,
  Select,
  Row,
  Col,
  InputNumber,
} from "@pankod/refine-antd";

interface IBasicInformationProps {
  patientID?: string;
  isFullScreen?: boolean;
  form?: any;
}

export const AddRecutForm: FC<IBasicInformationProps> = (props) => {
  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Scale" name="scale">
            <InputNumber style={{ width: "100%" }} step={1} min={1} max={27} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Recut Result" name="recutResult">
            <Select allowClear options={definitions["recutResult"]} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Comment" name="description">
            <Input.TextArea rows={4} style={{ maxHeight: 300 }} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
