export const components = {
  Header() {
    return (
      <div
        style={{
          textAlign: "center",
          // marginTop: "80px",
          // marginBottom: "16px",
        }}
      >
        {/* <span className="logo2">PAPUSHADO</span> */}
        <img
          src={"logo.png"}
          alt="Papushado"
          style={{
            width: "350px",
            padding: "32px",
          }}
        />
      </div>
    );
  },
};
